@import "_reset";
@import "_mixin";
@import "_spacing";

// Variable
$border: rgba(255, 255, 255, 0.5);

$border-radius-xs: 4px;
$border-radius-s: 8px;
$border-radius-m: 16px;
$border-radius-l: 50px;
$border-radius-xl: 50%;

// Override

:root {
  font-size: 16px;
}

body {
  font-family: "Outfit", sans-serif;
  background: #000000;
  line-height: 1.5;
  color: #ffffff;
  font-weight: 300;
  overflow: auto !important;
  padding: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.col {
  display: flex;
  flex-direction: column;

  .full {
    flex-grow: 1;
  }
}
.col-12 {
  width: 100%;
}
.col-9 {
  width: 75%;
}
.col-7 {
  width: 60%;
}
.col-6 {
  width: 50%;
}
.col-5 {
  width: 40%;
}
.col-4 {
  width: 33.333%;
}
.col-3 {
  width: 25%;
}

.bd-t {
  border-top: 1px solid $border;
}
.bd-b {
  border-bottom: 1px solid $border;
}
.bd-l {
  border-left: 1px solid $border;
}
.bd-r {
  border-right: 1px solid $border;
}
.bd-a {
  border: 1px solid $border;
}
.bd-x {
  border-left: 1px solid $border;
  border-right: 1px solid $border;
}
.bd-y {
  border-top: 1px solid $border;
  border-bottom: 1px solid $border;
}

.br-t {
  border-top-left-radius: $border-radius-s !important;
  border-top-right-radius: $border-radius-s !important;
}
.br-b {
  border-bottom-left-radius: $border-radius-s !important;
  border-bottom-right-radius: $border-radius-s !important;
}
.br-l {
  border-top-left-radius: $border-radius-s !important;
  border-bottom-left-radius: $border-radius-s !important;
}
.br-r {
  border-top-right-radius: $border-radius-s !important;
  border-bottom-right-radius: $border-radius-s !important;
}
.br-a {
  border-radius: $border-radius-s !important;
}

.d-block {
  display: block !important;
}
.d-inline-block {
  display: inline-block !important;
}

.d-flex {
  display: flex !important;

  &.flex-column {
    flex-direction: column;
  }
  &.flex-column-reverse {
    flex-direction: column-reverse;
  }
  &.flex-row {
    flex-direction: row;
  }
  &.flex-row-reverse {
    flex-direction: row-reverse;
  }
  &.flex-wrap {
    flex-wrap: wrap;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-space-around {
    justify-content: space-around;
  }
  &.justify-space-between {
    justify-content: space-between;
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.align-start {
    align-items: flex-start;
  }
  &.align-end {
    align-items: flex-end;
  }
  &.align-center {
    align-items: center;
  }
  &.align-baseline {
    align-items: baseline;
  }
  &.align-stretch {
    align-items: stretch;
  }
}

.flex-grow {
  flex-grow: 1;
}
.flex-shrink {
  flex-shrink: 0 !important;
}
.align-self-start {
  align-self: start !important;
}
.align-self-center {
  align-self: center !important;
}

.vertical-scroll {
  @include vertical-scroll;
}
.horizon-scroll {
  @include horizon-scroll;
}

.pos-relative {
  position: relative;
}

.bg-gradient {
  background: linear-gradient(89.85deg, #04b1fb 2.64%, #402ff2 103.58%);
}

.ellipsis {
  @include ellipsis;
}

.ellipsis-2 {
  @include multi-ellipsis(2);
}

.ratio1-1 {
  @include aspect-ratio(1, 1);
}

.color-gradient {
  background: linear-gradient(to right, #40c5ea 30%, #353edb 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
