@function calculateRem($size) {
  @return ($size/16);
}

@mixin transition($sec) {
  -webkit-transition: $sec;
  -moz-transition: $sec;
  -ms-transition: $sec;
  -o-transition: $sec;
  transition: $sec;
}

@mixin placeholder($color, $fontWeight) {
  &::-webkit-input-placeholder {
    color: $color;
    font-weight: $fontWeight;
  }
  &:-ms-input-placeholder {
    color: $color;
    font-weight: $fontWeight;
  }
  &::placeholder {
    color: $color;
    font-weight: $fontWeight;
  }
}

@mixin flex-center {
  display: flex;
  align-items: center;
}
@mixin flex-baseline {
  display: flex;
  align-items: baseline;
}
@mixin box-shadow {
  box-shadow: 0 6px 8px rgba(102, 119, 136, 0.03),
    0 1px 2px rgba(102, 119, 136, 0.3);
}

@mixin ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@mixin multi-ellipsis($line) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin clearfix {
  &::after {
    content: '';
    clear: both;
    display: table;
  }
}

@mixin vertical-scroll {
  overflow-x: hidden;
  overflow-y: auto;
}
@mixin horizon-scroll {
  overflow-x: auto;
  overflow-y: hidden;
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  & > * {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }
}

@mixin prefix($property, $value) {
  $prefixes: webkit, moz, 0;

  @each $prefix in $prefixes {
    #{'-' + $prefix + '-' + $property}: $value;
  }

  // Output standard non-prefixed declaration
  #{$property}: $value;
}
